import { createFromIconfontCN } from "@ant-design/icons-vue";
import { defineComponent, VNode, PropType } from "vue";

const MyIcon = createFromIconfontCN({
  scriptUrl:
    "https://lf1-cdn-tos.bytegoofy.com/obj/iconpark/svg_18657_23.22a9e195a342545624b5e4bf579c3720.js", // 在 iconfont.cn 上生成
});

type IconName = string;

interface IconProps {
  name: IconName;
}

export default defineComponent({
  props: {
    name: String as PropType<IconName>,
  },
  setup(props) {
    return (): VNode => <MyIcon type={(props as IconProps).name} />;
  },
});

import { defineComponent, h, PropType, VNode, computed, onMounted } from 'vue'
import {Input, Select, DatePicker, SelectOption} from 'ant-design-vue'

const formItemMap: any = {
    'input': Input,
    'select': Select,
    'date-picker': DatePicker
}

export interface ComConfig{
    tag: string,
    key: string,
    valueKey?: string,
    labelKey?: string,
    slots?: string | ((comConfig: ComConfig) => VNode)
    getDataApi?: () => any,
    props: any
}
export default defineComponent({
    props: {
        comConfig: Object as PropType<ComConfig>,
        value: [String, Number]
    },
    setup(props: any, {emit}) {
        const handleChange = (event: InputEvent) => {
            emit('update:value', event)
        }
        onMounted(() => {
        })
        const component = formItemMap[props.comConfig.tag]
        return () => h(component, {
            ...props.comConfig.props,
            allowClear: true,
            value: props.value,
            'onUpdate:value': handleChange
        })
    }
})
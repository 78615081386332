import {
  defineComponent,
  mergeProps,
  reactive,
  computed,
  toRefs,
  onMounted,
  renderSlot,
  PropType,
} from "vue";
import { tableProps, TableProps } from "ant-design-vue/lib/table/interface";
import "./table.less";
import myIcon from "@/components/icon/index";
import axios from '@/axios-config'
interface TProps extends TableProps {
  sourceData: (params: any) => any;
  downloadUrl: string | null;
  extraHeight: string | number;
}


export default defineComponent({
  inheritAttrs: false,
  components: {
    myIcon,
  },
  props: {
    // ...mergeProps(tableProps, {
    //   sourceData: Function,
    //   downloadUrl: String,
    //   extraHeight: [String, Number],
    // }),
    sourceData: Function,
    downloadSelectApi: String,
    downloadApi: Function,
    extraHeight: [String, Number],
  },
  setup(
    props: any,
    context: { attrs: any; slots: any; emit: any; expose: any }
  ) {
    const { attrs, slots, emit, expose } = context;
    const comData = reactive({
      loading: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total: number) => `总数 ${total} 条`,
      },
      scroll: {},
      sorter: {
        columnKey: "",
        order: "",
      },
      tableData: [],
    });
    const headerShow = computed((): boolean => {
      return !!slots.headerLeft || !!props.downloadUrl;
    });
    const handleFileDown = () => {
      if(props.downloadApi) {
        props.downloadApi.call(null, ...arguments)
      }
      // if(props.downloadUrl) {
      //   axios.get(props.downloadUrl).then(res => {
      //     console.log(res)
      //   })
      // }
    };
   const handleFileSelectDown = ()=>{
    if(props.downloadSelectApi) {
      props.downloadSelectApi.call(null, ...arguments)
    }
   }
    const refresh = (page:number | undefined) => {
      page && (comData.pagination.current = (page as number));
      $init();
    };
    var sort='createdTime:desc'
    const getQuery = () => {
      const { current: pageIndex, pageSize } = comData.pagination;
      const { columnKey, order } = comData.sorter;
      sort='createdTime:desc'
      if(columnKey=='createdTime'){
        if(order=='ascend'){
          sort=columnKey+':asc'
        }else if(order=='descend'){
          sort=columnKey+':desc'
        }
      }
      return {
        pageIndex,
        pageSize,
        sort
      };
    };
    const $initAttrs = () => {
      comData.scroll = (props as TableProps).scroll || {};
    };
    const $init = () => {
      comData.loading = true;
      if (props.sourceData && typeof props.sourceData === "function") {
        const params = getQuery();
        props
          .sourceData(params)
          .then((res: any) => {
            comData.tableData = res.data;
            const { pageSize } = comData.pagination;
            comData.pagination = {
              ...comData.pagination,
              current: res.pageIndex || 1,
              pageSize: res.pageSize || pageSize,
              total: res.totalCount || 0,
            };
            if (
              res.data &&
              !res.data.length &&
              comData.pagination.current > 1
            ) {
              comData.pagination.current--;
              $init();
            }
          })
          .finally(() => {
            comData.loading = false;
          });
      }
    };
    const tableChange = (
      pagination: {
        current: number;
        pageSize: number;
        total: number;
        showQuickJumper: boolean;
        showSizeChanger: boolean;
        showTotal: (total: number) => string;
      },
      filter: any,
      sorter: { columnKey: string; order: string },
      { currentDataSource }: any
    ) => {
      comData.pagination = pagination;
      comData.sorter = sorter;
      $init();
      emit("change", pagination, filter, sorter, { currentDataSource });
    };
    onMounted(() => {
      $initAttrs();
      $init();
    });
    expose({
      refresh,
    });
    return () => (
      <div class="table__wrapper">
        {headerShow.value && (
          <div class="table__header_wrapper">
            <div>{renderSlot(slots, "headerLeft")}</div>
            {(props.downloadUrl || props.downloadApi)&& (
              <div class="dropdown">
                <button class="dropbtn"> <myIcon name="export" />导出表格</button>
                <div class="dropdown-content">
                  <span onClick={handleFileDown}>导出全部内容</span>
                  <span onClick={handleFileSelectDown}>导出所选内容</span>
                </div>
              </div>
              // <a-button
              //   type="default"
              //   disabled={!comData.tableData.length}
              //   // onClick={handleFileDown}
              // >
              //   <myIcon name="export" />
              //   导出表格
              // </a-button>
            )}
          </div>
        )}
        <div class="table__content__wrapper">
          <a-table
            {...{
              ...attrs,
              "data-source": comData.tableData,
              loading: comData.loading,
              scroll: comData.scroll,
              pagination:
                props.pagination !== undefined
                  ? props.pagination
                  : comData.pagination,
              attrs: {
                class: props.extraHeight ? "table--layout" : "",
              },
              onChange: tableChange,
            }}
            v-slots={slots}
          ></a-table>
        </div>
      </div>
    );
  },
});


